import React, { Fragment, useContext, useState, useEffect } from 'react';
import { AppContext } from './../../../../Application/provaider';
import Lottie from "./../../../../Shared/Components/lottie.success.component";
import diaAdia from './../../../../Assets/Images/dia_a_dia.png';
import ToolService from '../../Service/toolService'; 
import axios from 'axios';
import { useForm } from 'react-hook-form';

const Home = () => {
    const baseUrl = 'https://chanitos.net/api';
    //const baseUrl = 'http://localhost:3000/api';
    const [state, setState] = useContext(AppContext);
    const [loading, setLoading] = useState(false);
    
    let state_copy = state;
    let defaultValue = {};
    const { register, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultValue });

    const onSubmit = async (data) => {
        try {
            setLoading(true);
            const response = await getCandidate(data);
            if (response.code == "OPERATION_SUCCESS") {

                const data = response.mesagge;
                console.log(data);
                state_copy.started = true;
                state_copy.idCandidate = data.candidato.idCandidato;

                //Mapeamos en el state los datos del servicio

                //Datos personales
                if (data.candidato) {
                    state_copy.dataPersonal = saveDataCandidate(data.candidato);
                }

                console.log(state_copy.dataPersonal);
                //Experiencia laboral
                if (data.experienciaLaboral && data.experienciaLaboral.length > 0) {
                    state_copy.dataExperience = saveDataLaboralExperience(data.experienciaLaboral);
                }


                //Educacion
                if (data.educacion && data.educacion.length > 0) {
                    state_copy.dataEducation = saveDataEducation(data.educacion);
                }

                //Otros
                if (data.informacionadicional) {
                    state_copy.dataOther = saveDataOther(data.informacionadicional)
                }
                setState(state_copy);

                ToolService.successMesagge('Candidato Encontrado');

            } else {
                ToolService.errorMesagge('Candidato no encontrado');
            }
            setLoading(false);
        } catch (error) {
            ToolService.errorMesagge('Candidato no encontrado');
            setLoading(false);
        }
    };


    const saveDataCandidate = (data) => {

        console.log('Id estado: ' + data.idEstado)
        console.log('Fecha de nacimiento: ' + new Date(data.fechaNacimiento).toISOString().slice(0, 10))
        
        const payload = {
            name: data.nombre,
            firstName: data.apellidoP,
            secondName: data.apellidoM,
            direction: data.direccion,
            city: data.ciudad,
            state: ToolService.getValueState(data.idEstado),
            cp: data.cp,
            phoneHome: data.telefono,
            email: data.email,
            birthdate: new Date(data.fechaNacimiento).toISOString().slice(0, 10),
            jobNeed: data.puestoDeseado,
            money: data.expectativaMensualBruto
        };
        return {
            data: payload,
            status: true
        };
    }

    const saveDataLaboralExperience = (data) => {

        let listExperience = [];
        data.forEach(element => {
            listExperience.push({
                nameRol: element.nombreEmpresa,
                reason: element.razonRetiro
            });
        });
        return {
            data: listExperience,
            status: true
        };

    }

    const saveDataEducation = (data) => {
        let listEducation = [];
        data.forEach(element => {
            listEducation.push({
                school: element.escuela,
                title: element.titulo,
                average: element.promedio
            });
        });
        return {
            data: listEducation,
            status: true
        };
    }


    const saveDataOther = (data) => {
        return {
            data: {
                organization: data.organizacionesRelevantes,
                habilities: data.formacionesHabilidadesFortalezas,
                debilities: data.flaquezaDebilidades,
                disponibility: data.disponibilidadCambiarResidencia ? 'true' : 'false',
                whynot: data.razon,
                work: data.empleadoLegal ? 'true' : 'false',
                callboos: data.concretarLlamadas ? 'true' : 'false',
                whynotBoos: data.razonConcretarLlamadas,
                notes: data.comentarios
            },
            status: true
        }
    }



    const getCandidate = async (payload) => {
        const response = await axios.get(`${baseUrl}/hiring/search/${payload.phone}`);
        return response.data;
    }

    return (
        <Fragment>
            <div className='row'>
                <div className="col-md-6">
                    <Lottie url="https://assets10.lottiefiles.com/packages/lf20_lcuhab4s.json"></Lottie>
                </div>
                <div className="col-md-6">
                    <h5>Gracias por completar el formulario de historia profesional. Por favor lea estas instrucciones cuidadosamente antes de llenar el formulario.</h5><br></br>
                    <ul>
                        <li>Las preguntas con este símbolo (*) son obligatorias.</li>
                        <li>Recuerde guardar sus respuestas antes de continuar con la siguiente sección. Si no perderá la información ingresada.</li>
                        <li>Una vez guardada la información pasé a la siguiente sección.</li>
                        <li>En cuanto finalice con todas las secciones, la información le llegara a nuestro equipo de RH y se pondrán en contacto con usted en cuanto revisen su postulación.</li>
                    </ul>
                </div>
            </div>
            <div className='alert alert-warning mt-3 text-center' role="alert" >
                CONTINÚA TU REGISTRO
            </div>

            <div className='row mt-3'>
                <div className='col-md-6'>
                    <h5>Si comenzaste tu registro, puedes continuarlo ingresando tú número telefónico.</h5><br></br>
                </div>
                <div className='col-md-6'>
                    <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>
                        <label htmlFor="firstName" className='form-label label-required'>Telefono</label>
                        <input type="text" placeholder='55......' className={errors.phone ? 'form-control is-invalid' : 'form-control'} id="phone"  {...register('phone', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.firstName && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                        <button type="submit" className='btn btn-success-chanitos'>Ingresar</button>
                    </form>
                </div>
            </div>


            <div className='alert alert-warning mt-3 text-center' role="alert" >
                CONOCE UN POCO DE NOSOTROS
            </div>
            <div className='row mt-3'>

                <div className='col-md-6'>
                    <p>Somos un productor, comercializador, madurador, distribuidor y exportador verticalmente integrado de bananas en México. Nuestro por qué es: "Cultivamos energía natural para que alcances tus sueños". Cada caja vendida de plátano es un sueño logrado.</p>
                    <p>En Chanitos desarrollamos una agricultura responsable porque respetamos el entorno, la cultura y el medio ambiente, creando fincas preocupadas por todo lo que las rodea. En conjunto, las comunidades y los trabajadores, colaboramos para lograr la mejor banana del mundo y así, todos tengamos la energía natural para alcanzar nuestros sueños. Porque si todos los días la gente tiene la energía para hacer lo que le gusta, entonces podrán dar lo</p>
                    <p>Nuestra promesa a los clientes es que somos la banana nutritiva que da la energía para que hagas lo que más te gusta y te apasiona de manera divertida.</p>
                </div>
                <div className='col-md-6'>
                    <p>Nuestros valores son las reglas del juego y determinan cómo hacemos las cosas:</p>
                    <ul>
                        <li>Chanitos somos Todos</li>
                        <li>Somos energía natural</li>
                        <li>Soñamos despiertos y en grande</li>
                        <li>Buscamos cómo sí</li>
                        <li>Hacemos más con menos</li>
                        <li>Sólo jugadores "A"</li>
                        <li>Predicamos con el ejemplo</li>
                    </ul>
                    <strong>¿Qué hacemos como Grupo Chanitos? Enfocamos nuestros esfuerzos en 5 actividades clave:</strong><br />
                    <img src={diaAdia} alt="chanitos_dia_a_dia" className='diaadia' />
                </div>
            </div>
            <div id="overlay" style={{ display: loading ? 'flex' : 'none' }}>
                <Lottie url="https://assets9.lottiefiles.com/private_files/lf30_vamrx5qj.json" className="litty"></Lottie>
            </div>
        </Fragment>
    );
}

export default Home;