import React, { Fragment, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from './../../../../Application/provaider';
import PersonaFaqs from "./personal-data-faqs.component";
import ListStates from "./../../Service/catalogs";
import ToolService from "./../../Service/toolService";
import axios from 'axios';
const PersonalSection = () => {
    const baseUrl = 'https://chanitos.net/api';
    //const baseUrl = 'http://localhost:3000/api';
    const [state, setState] = useContext(AppContext);
    const toolService = ToolService;
    let defaultValue = {};
    const dataHold = state;
    if (dataHold && dataHold.dataPersonal) {
        defaultValue = dataHold.dataPersonal.data;
    }

    const setPersonalData = async (dataInformation) => {

        const payload = {
            nombre: dataInformation.name,
            apellidoPaterno: dataInformation.firstName,
            apellidoMaterno: dataInformation.secondName,
            direccion: dataInformation.direction,
            ciudad: dataInformation.city,
            estado: toolService.getIdState(dataInformation.state),
            cp: dataInformation.cp,
            telefono: dataInformation.phoneHome,
            email: dataInformation.email,
            telefonoCelular: dataInformation.phoneHome,
            fechaNacimiento: dataInformation.birthdate,
            puestoDeseado: dataInformation.jobNeed,
            expectativaMensualBruto: dataInformation.money
        };
        const response = await axios.post(`${baseUrl}/hiring/save/candidate`, payload);
        return response.data;
    }


    const saveComponent = <div className='col-md-12 d-flex flex-row-reverse'>
        <button type="submit" className='btn btn-success-chanitos'>Guardar</button>
    </div>;


    const { register, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultValue });

    const onSubmit = async (data) => {
        let state_copy = state;

        const response = await setPersonalData(data);
        if (response.code == "OPERATION_SUCCESS") {
            state_copy.dataPersonal = {
                data,
                status: true
            };
            //state_copy.idCandidate = response.mesagge;
            state_copy.idCandidate = response.mesagge;
            console.log(state_copy);
            setState(state_copy);
        } else {

        }

    }

    const alertSuccess = <div className='alert alert-success text-center' role="alert">
        Tu información fue guardada con exito por favor pase a la pestaña de "Experiencia laboral"
    </div>

    const ListEstates = ListStates;

    useEffect(() => {
        console.log(state);
    }, []);



    return (
        <Fragment>
            <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>
                <div className='row mb-3'>
                    <div className='col-md-12 mb-3'>
                        <h4 className='tittleSection' >Datos generales</h4>
                    </div>
                    <PersonaFaqs></PersonaFaqs>
                    <hr />

                </div>
                <div className='row'>
                    <div className='col-md-4 '>
                        <label htmlFor="name" className='form-label label-required' >Nombre(s)</label>
                        <input type="text" className={errors.name ? 'form-control is-invalid' : 'form-control'} id="name"  {...register("name", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.name && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="firstName" className='form-label label-required'>Apellido paterno</label>
                        <input type="text" className={errors.firstName ? 'form-control is-invalid' : 'form-control'} id="firstName"  {...register('firstName', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.firstName && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="secondName" className='form-label label-required'>Apellido materno</label>
                        <input type="text" className={errors.secondName ? 'form-control is-invalid' : 'form-control'} id="secondName"  {...register('secondName', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.secondName && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                </div>

                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <label htmlFor="birthdate" className='form-label label-required'>Fecha de nacimiento</label>
                        <input type="date" className={errors.birthdate ? 'form-control is-invalid' : 'form-control'} id="birthdate"  {...register('birthdate', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.birthdate && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>


                    <div className='col-md-8'>
                        <label htmlFor="direction" className='form-label label-required'>Dirección</label>
                        <input type="text" className={errors.direction ? 'form-control is-invalid' : 'form-control'} id="direction"  {...register('direction', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.direction && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>


                <div className="row mt-3">
                    <div className='col-md-4'>
                        <label htmlFor="city" className='form-label label-required'>Ciudad</label>
                        <input type="text" className={errors.city ? 'form-control is-invalid' : 'form-control'} id="city"  {...register('city', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.city && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="state" className='form-label label-required'>Estado</label>
                        <select className={errors.state ? 'form-control is-invalid' : 'form-control'} id="state"  {...register('state', { required: true })} >
                            {ListEstates.map((estado, index) => (
                                <option key={index} value={estado.value}>{estado.label}</option>
                            ))}

                        </select>

                        <div className="invalid-feedback d-flex">
                            {errors.state && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                </div>


                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <label htmlFor="cp" className='form-label label-required'>Codigo Postal</label>
                        <input type="number" className={errors.cp ? 'form-control is-invalid' : 'form-control'} id="cp"  {...register('cp', { required: true, pattern: /^\d+$/ })} />
                        <div className="invalid-feedback d-flex">
                            {errors.cp && <small className="text-error-input">Este campo es requerido y solo números</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="phoneHome" className='form-label label-required'>Clave lada + no. telefónico</label>
                        <input type="number" className={errors.phoneHome ? 'form-control is-invalid' : 'form-control'} id="phoneHome"  {...register('phoneHome', { required: true, pattern: /^\d+$/ })} />
                        <div className="invalid-feedback d-flex">
                            {errors.phoneHome && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="email" className='form-label label-required'>Dirección de correo electrónico</label>
                        <input type="email" className={errors.email ? 'form-control is-invalid' : 'form-control'} id="email"  {...register('email', { required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g })} />
                        <div className="invalid-feedback d-flex">
                            {errors.email && <small className="text-error-input">Este campo es requerido y formato email</small>}
                        </div>
                    </div>
                </div>


                <div className='row mt-3'>
                    <div className='col-md-4'>
                        <label htmlFor="jobNeed" className='form-label label-required'>Puesto al que desea postularse</label>
                        <input type="text" className={errors.jobNeed ? 'form-control is-invalid' : 'form-control'} id="jobNeed"  {...register('jobNeed', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.jobNeed && <small className="text-error-input">Este campo es requerido y solo números</small>}
                        </div>
                    </div>
                    <div className='col-md-5'>
                        <label htmlFor="money" className='form-label label-required'>Expectativa económica MXM Mensual BRUTO</label>
                        <input type="number" className={errors.money ? 'form-control is-invalid' : 'form-control'} id="money"  {...register('money', { required: true, pattern: /^\d+$/ })} />
                        <div className="invalid-feedback d-flex">
                            {errors.money && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-12'>
                        <hr />
                    </div>
                    {state.dataPersonal && state.dataPersonal.status ? <></> : saveComponent}
                </div>
                {state.dataPersonal && state.dataPersonal.status ? alertSuccess : <></>}
            </form>
        </Fragment >
    );
}

export default PersonalSection;