import React, { Fragment } from 'react';
const ExperienceFaqs = () => {
    return (
        <Fragment>
            <div className="col-md-12 mb-3">
                <span className="faqs__tittle" >Instrucciones</span>
                <ul className='faqs__list'>
                    <li style={{ marginTop: "1rem", marginBottom: "1rem" }}><strong>Es necesario llenar como MINIMO, tus últimas 3 posiciones laborales, (ya sea en la misma empresa o en diferentes compañías).
                        En caso de no cumplir este requisito, se te solicitará volver a llenar el formato.</strong></li>
                    <li style={{ marginTop: "1rem", marginBottom: "1rem" }}><strong>Comience con su trabajo actual o el más reciente y retroceda en el tiempo hasta que haya completado la forma con su primer trabajo de tiempo completo. Si tuvo diferentes puestos en la misma compañía, favor de llenar un formato para cada uno de los puestos.</strong></li>
                    <li>Las preguntas con este simbolo (*)¨son obligatorias.</li>
                    <li>Cuenta con un máximo de 8 trayectorias.</li>
                    <li>Antes de pasar a otra seccion recuerda guardar tu información.</li>
                </ul>

            </div>



        </Fragment>
    );
}

export default ExperienceFaqs;