import React, { Fragment } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';


export default function Lottie(props) {
    return (
        <Fragment>
            <Player
                //ref={props.player} // set the ref to your class instance
                autoplay={true}
                loop={true}
                controls={false}
                src={props.url}
                style={{ width: '80%' }}
                speed="1"
                mode="bounce"
                background="transparent"
            ></Player>
        </Fragment>
    );
}

