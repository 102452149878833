const ListStates = [
    { id: 1, value: "AGS", label: "Aguascalientes" },
    { id: 2, value: "BCN", label: "Baja California" },
    { id: 3, value: "BCS", label: "Baja California Sur" },
    { id: 4, value: "CAM", label: "Campeche" },
    { id: 5, value: "COA", label: "Coahuila de Zaragoza" },
    { id: 6, value: "COL", label: "Colima" },
    { id: 7, value: "CHP", label: "Chiapas" },
    { id: 8, value: "CHI", label: "Chihuahua" },
    { id: 9, value: "DIF", label: "CDMX" },
    { id: 10, value: "DUR", label: "Durango" },
    { id: 11, value: "GTO", label: "Guanajuato" },
    { id: 12, value: "GRO", label: "Guerrero" },
    { id: 13, value: "HGO", label: "Hidalgo" },
    { id: 14, value: "JAL", label: "Jalisco" },
    { id: 15, value: "MEX", label: "México" },
    { id: 16, value: "MIC", label: "Michoacán de Ocampo" },
    { id: 17, value: "MOR", label: "Morelos" },
    { id: 18, value: "NAY", label: "Nayarit" },
    { id: 19, value: "NLE", label: "Nuevo León" },
    { id: 20, value: "OAX", label: "Oaxaca" },
    { id: 21, value: "PUE", label: "Puebla" },
    { id: 22, value: "QRO", label: "Querétaro" },
    { id: 23, value: "ROO", label: "Quintana Roo" },
    { id: 24, value: "SLP", label: "San Luis Potosí" },
    { id: 25, value: "SIN", label: "Sinaloa" },
    { id: 26, value: "SON", label: "Sonora" },
    { id: 27, value: "TAB", label: "Tabasco" },
    { id: 28, value: "TAM", label: "Tamaulipas" },
    { id: 29, value: "TLX", label: "Tlaxcala" },
    { id: 30, value: "VER", label: "Veracruz" },
    { id: 31, value: "YUC", label: "Yucatán" },
    { id: 32, value: "ZAC", label: "Zacatecas" }
];

export default ListStates;
