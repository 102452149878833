import React, { Fragment, useState, useContext } from 'react';
import { AppContext } from './../../../Application/provaider';
import FormRecruitment from './../Components/form.template';
function Recruitement() {
    const [screen, setScreen] = useState(0);
    const [state, setState] = useContext(AppContext);
    const changePage = (page) => {
        if (permisse(page)) {
            setScreen(page);
        }
        //setScreen(page);
    }

    const permisse = (page) => {
        const dataState = state;
        if (page === 0) {
            return true;
        }
        if (page === 1) {
            return true;
        }

        if (page === 2) {
            if (dataState.dataPersonal && dataState.dataPersonal.status) { return true; }
        }
        if (page === 3) {
            if (dataState.dataExperience && dataState.dataExperience.status) { return true; }
        }
        if (page === 4) {
            if (dataState.dataEducation && dataState.dataEducation.status) { return true; }
        }
        if (page === 5) {
            if (dataState.dataOther && dataState.dataOther.status) { return true; }
        }

        return false;
    }

    return (
        <Fragment>
            <div className='row mb-5'>
                <div className='col-md-1'>
                </div>
                <div className='col-md-10 recruitment__container'>
                    <ul className="nav nav-tabs mb-5">
                        <li className="nav-item">
                            <span className={screen === 0 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(0)}>Instrucciones</span>
                        </li>
                        <li className="nav-item">
                            <span className={screen === 1 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(1)}>Datos generales</span>
                        </li>
                        <li className="nav-item">
                            <span className={screen === 2 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(2)}>Experiencia laboral</span>
                        </li>
                        <li className="nav-item">
                            <span className={screen === 3 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(3)}>Trayectoria Académica</span>
                        </li>
                        <li className="nav-item">
                            <span className={screen === 4 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(4)}>Información adicional</span>
                        </li>
                        <li className="nav-item">
                            <span className={screen === 5 ? 'nav-link active' : 'nav-link'} onClick={() => changePage(5)}>Finalizar</span>
                        </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                        <div className={screen === 0 ? 'tab-pane fade show active' : 'tab-pane fade'} id="home" role="tabpanel" aria-labelledby="home-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                        <div className={screen === 1 ? 'tab-pane fade show active' : 'tab-pane fade'} id="personalData" role="tabpanel" aria-labelledby="home-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                        <div className={screen === 2 ? 'tab-pane fade show active' : 'tab-pane fade'} id="experienceData" role="tabpanel" aria-labelledby="profile-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                        <div className={screen === 3 ? 'tab-pane fade show active' : 'tab-pane fade'} id="educationData" role="tabpanel" aria-labelledby="contact-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                        <div className={screen === 4 ? 'tab-pane fade show active' : 'tab-pane fade'} id="otherData" role="tabpanel" aria-labelledby="contact-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                        <div className={screen === 5 ? 'tab-pane fade show active' : 'tab-pane fade'} id="finishData" role="tabpanel" aria-labelledby="contact-tab"><FormRecruitment screenActive={screen}></FormRecruitment></div>
                    </div>

                </div>
                <div className='col-md-1'>
                </div>
            </div>
        </Fragment>
    );
}

export default Recruitement;