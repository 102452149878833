import React, { Fragment, useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { uid } from 'uid';
import { AppContext } from './../../../../Application/provaider';
import Profileeducation from "./../../../../Shared/Components/lottie.profile.education.component";
import EducationFaqs from "./education.faqs.components";
import ToolService from "./../../Service/toolService";
import axios from 'axios';
function EducationSection() {
    const baseUrl = 'https://chanitos.net/api';
    //const baseUrl = 'http://localhost:3000/api';
    const [state, setState] = useContext(AppContext);
    const [viewSuccess, setViewSuccess] = useState(false);
    const [listDataEducation, setListDataEducation] = useState([]);
    const { register, resetField, formState: { errors }, handleSubmit, reset } = useForm();
    const [extracurricular, setExtracurricular] = useState(''); //Input extracurricular
    const [listExtracurricular, setListExtracurricular] = useState([]);//Lista de extracurricular


    const listItem = listExtracurricular;
    const listExtracurricularDom = listItem.map((element) =>
        <li className='list-group-item d-flex justify-content-between list__itemAdd' key={element.uids}>
            {element.data} <span className='delete-list' onClick={() => { handleClick(element.uids) }}>Eliminar</span>
        </li>
    );

    const [viewTittle, setViewTittle] = useState(false);//Indica si se ve el campo de explicacion


    useEffect(() => {
        let defaultValue = [];
        const dataHold = state;
        if (dataHold && dataHold.dataEducation) {
            defaultValue = dataHold.dataEducation.data;
            setViewSuccess(dataHold.dataEducation.status);
        }
        setListDataEducation(defaultValue);

    }, [state]);

    const onSubmit = (data, e) => {
        console.log(e);
        let dataEducation = data;//Datos del formulario
        dataEducation.extraActivities = listExtracurricular;//Lista extracurricular
        dataEducation.uid = uid();//Id de card
        let copyList = [...listDataEducation, dataEducation];
        console.log(copyList);
        setListDataEducation(copyList);

        //Reseteamos formulario
        setListExtracurricular([]);

        reset();

    };

    const addActivity = () => {
        let data = extracurricular;
        let aux_Activity = [...listExtracurricular];
        if (data) {
            let request = {
                uids: uid(),
                data
            }
            aux_Activity.push(request);
            setListExtracurricular(aux_Activity);
            setExtracurricular('');
        }


    }//Agregar extracurricular


    const handleClick = (uid) => {
        const newListExperience = listExtracurricular.filter((item) => item.uids !== uid);
        setListExtracurricular(newListExperience);

    };//Eliminamos un extracurricular

    const deleteCardEducation = (uid) => {
        const newListEducation = listDataEducation.filter((item) => item.uid !== uid);
        setListDataEducation(newListEducation);
    }

    const save = async () => {
        let state_copy = state;
        state_copy.dataEducation = {
            data: listDataEducation,
            status: true
        };

        const listEducation = [];
        listDataEducation.forEach(element => {
            listEducation.push({
                escuela: element.school,
                tieneTitulo: element.isTitle == 'true' ? true : false,
                titulo: element.title,
                fechaIngreso: element.beginSchool,
                fechaEgreso: element.endSchool,
                promedio: element.average,
                extracurricular: element.extraActivities
            });
        });
        console.log('Request');

        const request = {
            education: listEducation,
            idCandidate: state_copy.idCandidate
        }
        console.log(request);
        const response = await setEducation(request);
        if (response.code == "OPERATION_SUCCESS") {
            setState(state_copy);
            setViewSuccess(true);
        } else {

        }
    }

    const setEducation = async (payload) => {
        try {
            const response = await axios.post(`${baseUrl}/hiring/save/candidate/education`, payload);
            return response.data;
        } catch (error) {
            return {
                code: "OPERATION_FAIL",
                mesagge: "Ocurrion un error al guardar la información"
            };
        }
    }


    //Inputs
    const onChangeHandler = (e) => {
        console.log(e)
        if (e.target.name === "isTitle") {
            let valueIstittle = e.target.value === 'true' ? true : false;
            setViewTittle(valueIstittle);
            resetField('title');
        }

        if (e.target.name === "extra") {
            setExtracurricular(e.target.value)
        }

    };



    //Componentes
    const card_component = <Fragment>
        <div className="row mt-4">
            {listDataEducation.map((element) =>
                <div className='col-md-4' key={uid()}>
                    <div className="card experience-card">
                        <div className="card-body experience-card-body">
                            <Profileeducation></Profileeducation>
                            <h5 className="card-title">{element.school}</h5>
                            <h6 className="card-subtitle mb-2 text-muted">{ element.title && typeof element.title === 'undefined'  ? element.title : ''}</h6>
                            <p className="card-text">Promedio final: {element.average}</p>
                            <span className="experience-card-delete" onClick={() => deleteCardEducation(element.uid)}>Eliminar</span>
                        </div>
                    </div>
                </div>
            )}
        </div>
    </Fragment>

    const viewTittleContent = <Fragment>
        <div className='col-md-12'>
            <label htmlFor="title" className='form-label' >Título</label>
            <input type="text" className={errors.title ? 'form-control is-invalid' : 'form-control'} id="title"  {...register("title", { required: false })} />
            <div className="invalid-feedback d-flex">
                {errors.title && <small className="text-error-input">Este campo es requerido</small>}
            </div>
        </div>
    </Fragment>



    const alertSuccess = <div className='alert alert-success text-center' role="alert">
        Tu información fue guardada con exito por favor pase a la pestaña de "Otros"
    </div>



    return (
        <Fragment>
            <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>
                <div className='row mb-3'>
                    <div className='col-md-12 mb-3'>
                        <h4 className='tittleSection'>Educación</h4>
                    </div>
                    <div className="col-md-12">
                        <EducationFaqs></EducationFaqs>
                        <hr />
                    </div>
                </div>

                <div className='row mb-4 mt-4'>
                    <div className='col-md-6'>
                        <label htmlFor="school" className='form-label label-required'>Escuela</label>
                        <input type="text" className={errors.school ? 'form-control is-invalid' : 'form-control'} id="school"  {...register("school", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.school && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-3'>
                        <label htmlFor="organization" className='form-label label-required mr-5' >¿Cuenta con título?</label>
                        <br />
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="isTittle" id="isTittle-si" value={true} {...register("isTitle", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="isTittle-si">SI</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="isTittle" id="isTittle-no" value={false} {...register("isTitle", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="isTittle-no">NO</label>
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.isTitle && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    {viewTittle ? viewTittleContent : <></>}




                </div>

                <div className='row mb-3'>

                    <div className='col-md-6'>
                        <label htmlFor="beginSchool" className='form-label label-required'>Fecha de inicio</label>
                        <input type="date" className={errors.beginSchool ? 'form-control is-invalid' : 'form-control'} id="beginSchool"  {...register("beginSchool", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.beginSchool && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-6'>
                        <label htmlFor="endSchool" className='form-label label-required'>Fecha de salida</label>
                        <input type="date" className={errors.endSchool ? 'form-control is-invalid' : 'form-control'} id="endSchool"  {...register("endSchool", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.endSchool && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                </div>


                <div className='row mb-3'>
                    <div className='col-md-4'>
                        <label htmlFor="average" className='form-label label-required' >Promedio</label>
                        <input type="number" step='any' className={errors.average ? 'form-control is-invalid' : 'form-control'} id="average"  {...register("average", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.average && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-8'>
                        <label htmlFor="average" className='form-label' >Actividades extracurriculares, honores y premio</label>
                        <div className="input-group mb-2">
                            <input type="text" className="form-control" id="extra" name="extra" value={extracurricular} onChange={onChangeHandler} />
                            <div className="input-group-prepend" onClick={() => { addActivity() }}>
                                <div className="input-group-text">Agregar</div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="row mb-3">
                    <div className='col-md-12'>
                        {
                            listExtracurricular.length > 0 ? <ul className='list-group'>{listExtracurricularDom} </ul> : <></>
                        }

                    </div>
                </div>

                <div className='row mt-3 mb-3'>
                    <div className='col-md-12'>
                        <hr />
                    </div>
                    <div className='col-md-12 d-flex flex-row-reverse'>
                        {listDataEducation.length < 3 ? <button type="submit" className='btn btn-success-chanitos' style={{ marginLeft: "1rem" }}>Agregar</button> : <></>}

                    </div>
                </div>




            </form>

            {card_component}

            {listDataEducation.length > 0 ? <div className='row mt-3 mb-3'>
                <div className='col-md-12'>
                    <hr />
                </div>
                {
                    state.dataEducation && state.dataEducation.status ? <></> : <div className='col-md-12 d-flex flex-row-reverse'>
                        <button className='btn btn-success-chanitos' onClick={() => save()}>Guardar</button>
                    </div>
                }


            </div> : <></>}




            {(state.dataEducation && state.dataEducation.status) || viewSuccess ? alertSuccess : <></>}
        </Fragment>
    );
}

export default EducationSection;