import React, { Fragment } from 'react';
import { Player } from '@lottiefiles/react-lottie-player';


export default function Profileeducation() {
    return (
        <Fragment>
            <Player
                //ref={props.player} // set the ref to your class instance
                autoplay={true}
                loop={true}
                controls={false}
                src="https://assets4.lottiefiles.com/private_files/lf30_whzqou2n.json"
                style={{ height: '150px', width: '150px' }}
                speed="1"
                background="transparent"
            ></Player>
        </Fragment>
    );
}

