import React, { Fragment } from 'react';
import platano_icon from './.././../../../Assets/Images/platano_icon.svg'
function ListBeneits(props) {
    const list = props.benefits;
    const listItems = list.map((element, index) =>
    
        <li className='list-group-item' key={index} > <img src={platano_icon} alt="icon_platano" className='icon_platano'/> - {element}</li>
    );
    return (
        <Fragment>
            <ul className="list-group list-group-flush">
                {listItems}
            </ul>
        </Fragment>
    );
}

export default ListBeneits;