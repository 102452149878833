import axios from 'axios';
//const baseUrl = 'https://chanitos.net/api';
const baseUrl = 'http://localhost:5000/api';
const RecluitmentService = {
    createDocument: async (data) => {
        const response = await axios.post(`${baseUrl}/document`, data);
        return response.data;
    },
    download: async (data) => {
        const response = await axios.post(`${baseUrl}/document/download`, data, {
            responseType: 'arraybuffer', responseEncoding: "binary", headers: {
                "Content-Type": "application/pdf"
            }
        });
        return response.data;
    },
    downloadpdf: async (data) => {
        axios.post(`${baseUrl}/document/downloadpdf`,
            data,
            {
                responseType: 'arraybuffer'
            })
            .then((response) => {
                const blob = new Blob([response.data], { type: "application/pdf" });
                const url = window.URL.createObjectURL(blob);
                // Crear link de descarga y apuntar al Blob URL
                const link = document.createElement("a");
                link.href = url;
                link.download = 'CV';
                document.body.appendChild(link);
                link.click();
                setTimeout(() => {
                    window.URL.revokeObjectURL(url);
                    document.body.removeChild(link);
                }, 0)
            })
            .catch((error) => console.log(error));
    },
    converter: async (data) => {
        const response = await axios.post(`${baseUrl}/document/converter`, data);
        return response.data;
    }
};

export default RecluitmentService;             