import ListStates from "./catalogs";
import alertify from "alertifyjs";

const ToolService = {

    getIdState: (payload) => {
        const listState = ListStates;
        const stateFilter = listState.filter(element => element.value == payload);
        if (stateFilter.length > 0) {
            return stateFilter[0].id;
        } else {
            return 1;
        }
    },
    getValueState: (idState) => {
        const listState = ListStates;
        const stateFilter = listState.filter(element => element.id == idState);
        if (stateFilter.length > 0) {
            return stateFilter[0].value;
        } else {
            return 1;
        }
    },
    successMesagge: (mesagge) => {
        alertify.set('notifier','position', 'top-right');
        alertify.success(mesagge);
    },
    errorMesagge: (mesagge) => {
        alertify.set('notifier','position', 'top-right');
        alertify.error(mesagge);
    }


}

export default ToolService;