import React from 'react';
import Provider from './Application/provaider';
import Recruitement from './Modules/Recruitment/Page/recruitment.page'
import Header from "./Shared/Components/header.component";
import Footer from "./Shared/Components/footer.component";


function App() {
  return (
    <Provider>
      <>
        <Header></Header>
        <Recruitement></Recruitement>  
      </>
    </Provider>

  );
}

export default App;
