import React, { useContext } from 'react';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { AppContext } from './../../../../Application/provaider';
import axios from 'axios';
function Other() {
    const baseUrl = 'https://chanitos.net/api';
    //const baseUrl = 'http://localhost:3000/api';
    const [state, setState] = useContext(AppContext);
    //Inicializamos datos
    let defaultValue = {};
    const dataHold = state;
    if (dataHold && dataHold.dataOther) {
        defaultValue = dataHold.dataOther.data;
    }
    const { register, resetField, formState: { errors }, handleSubmit } = useForm({ defaultValues: defaultValue });

    const [viewExplication, setExplication] = useState(true);//Indica si se ve el campo de explicacion
    const [viewExplicationCallBoos, setViewExplicationCallBoos] = useState(true);//Indica si se ve el campo de explicacion
    const [viewSuccess, setViewSuccess] = useState(false);//alert que avisa si esta correcto el formulario


    const onSubmit = async (data) => {
        let state_copy = state;
        state_copy.dataOther = {
            data,
            status: true
        };
        console.log(state_copy);


        const request = {
            organizacionesRelevantes: data.organization,
            formacionesHabilidadesFortalezas: data.habilities,
            flaquezaDebilidades: data.debilities,
            disponibilidadCambiarResidencia: data.disponibility == 'true' ? true : false,
            razon: data.whynot,
            empleadoLegal: data.work == 'true' ? true : false,
            concretarLlamadas: data.callboos == 'true' ? true : false,
            razonConcretarLlamadas: data.whynotBoos,
            comentarios: data.notes
        }
        console.log("Request");
        console.log(request);
        const response = await setAditionalInformation({
            informacionadicional: request,
            idCandidate: state_copy.idCandidate
        });

        if (response.code == "OPERATION_SUCCESS") {
            setState(state_copy);
            setViewSuccess(true);
        } else {

        }
    };

    const setAditionalInformation = async (payload) => {
        try {
            const response = await axios.post(`${baseUrl}/hiring/save/candidate/aditionalInformation`, payload);
            return response.data;
        } catch (error) {
            return {
                code: "OPERATION_FAIL",
                mesagge: "Ocurrion un error al guardar la información"
            };
        }
    }





    useEffect(() => {

        const dataHold = state;
        if (dataHold && dataHold.dataOther) {
            defaultValue = dataHold.dataOther.data;
            setViewSuccess(true);
        }

        defaultValue.disponibility === 'true' || !defaultValue.disponibility ? setExplication(true) : setExplication(false);
        defaultValue.callboos === 'true' || !defaultValue.callboos ? setViewExplicationCallBoos(true) : setViewExplicationCallBoos(false);
    }, [state]);


    //Componentes
    const explicateSection = <div className='col-md-12'>
        <label htmlFor="organization" className='form-label' >Explique ¿Por que no?</label>
        <textarea id="whynot" className={errors.whynot ? 'form-control is-invalid' : 'form-control'}   {...register("whynot", { required: !viewExplication ? true : false })}></textarea>
        <div className="invalid-feedback d-flex">
            {errors.whynot && <small className="text-error-input">Este campo es requerido</small>}
        </div>
    </div>;


    const explicateSectionCallBoos = <div className='col-md-12'>
        <label htmlFor="organization" className='form-label' >Explique ¿Por que no?</label>
        <textarea id="whynotBoos" className={errors.whynotBoos ? 'form-control is-invalid' : 'form-control'}   {...register("whynotBoos", { required: !viewExplicationCallBoos ? true : false })}></textarea>
        <div className="invalid-feedback d-flex">
            {errors.whynotBoos && <small className="text-error-input">Este campo es requerido</small>}
        </div>
    </div>;





    const alertSuccess = <div className='alert alert-success' role="alert">
        Tu información fue guardada con exito por favor pase a la pestaña de "Finalizar" para terminar con su proceso
    </div>

    //Inputs
    const onChangeHandler = e => {
        if (e.target.name === "disponibility") {
            let valueDisponibility = e.target.value === 'true' ? true : false;
            setExplication(valueDisponibility);
            resetField('whynot');
        }
        if (e.target.name === "callboos") {
            let valueCallBoos = e.target.value === 'true' ? true : false;
            setViewExplicationCallBoos(valueCallBoos);
            resetField('whynotBoos');
        }

    };


    return (
        <>
            <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>
                <div className='row mb-3'>
                    <div className='col-md-12 mb-3'>
                        <h4 className='tittleSection'>Información adicional</h4>
                    </div>
                    <div className="col-md-12 mb-3">
                        <span className="faqs__tittle">Instrucciones</span>
                        <ul className='faqs__list'>
                            <li>Las preguntas con este simbolo (*) son obligatorias.</li>
                            <li>Guarda las respuesta de esta seccion antes de pasar a la siguiente</li>
                            <li>Una vez guardada la información pase a la seccion de Descargar Historial</li>
                        </ul>
                        <hr />
                    </div>
                </div>

                <div className='row '>
                    <div className='col-md-12'>
                        <label htmlFor="organization" className='form-label' >Organizaciones profesionales o relevantes al empleo de las que es miembro.</label>
                        <input type="text" className={errors.organization ? 'form-control is-invalid' : 'form-control'} id="organization"  {...register("organization")} />
                        <div className="invalid-feedback d-flex">
                            {errors.organization && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label htmlFor="habilities" className='form-label label-required'>¿Qué formaciones, habilidades, y fortalezas le ayudarán a tener éxito en este empleo?</label>
                        <input type="text" className={errors.habilities ? 'form-control is-invalid' : 'form-control'} id="habilities"  {...register('habilities', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.habilities && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label htmlFor="debilities" className='form-label label-required'>¿Cuáles son sus flaquezas o debilidades que habría que mejorar?</label>
                        <input type="text" className={errors.debilities ? 'form-control is-invalid' : 'form-control'} id="debilities"  {...register('debilities', { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.debilities && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                </div>

                <div className='row'>
                    <div className="col-md-12">
                        <label htmlFor="organization" className='form-label label-required mr-5' >¿Tiene disponibilidad de cambiar de residencia?</label>
                    </div>
                    <div className='col-md-12'>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="disponibility" id="disponibility-si" value={true} {...register("disponibility", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="disponibility-si">SI</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="disponibility" id="disponibility-no" value={false} {...register("disponibility", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="disponibility-no">NO</label>
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.disponibility && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    {!viewExplication ? explicateSection : <></>}

                    <div className='col-md-12'>
                        <label htmlFor="habilities" className='form-label label-required'>¿Puede trabajar como empleado legal dentro La República Mexicana?</label>
                    </div>
                    <div className='col-md-12'>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="work" id="work-si" value={true} {...register("work", { required: true })} />
                            <label className="form-check-label" htmlFor="work-si">SI</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="work" id="work-no" value={false} {...register("work", { required: true })} />
                            <label className="form-check-label" htmlFor="work-no">NO</label>
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.work && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-12'>
                        <label htmlFor="debilities" className='form-label label-required'>
                            ¿Estaría dispuesto a aceptar que concretemos las llamadas de referencia con los últimos jefes que haya tenido como último paso antes de una oferta de empleo?
                        </label>
                    </div>
                    <div className='col-md-12'>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="callboos" id="callboos-si" value={true} {...register("callboos", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="callboos-si">SI</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="callboos" id="callboos-no" value={false}  {...register("callboos", { required: true })} onChange={onChangeHandler} />
                            <label className="form-check-label" htmlFor="callboos-no">NO</label>
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.callboos && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    {!viewExplicationCallBoos ? explicateSectionCallBoos : <></>}
                </div>

                <div className='row'>
                    <div className='col-md-12'>
                        <div className='form-group'>
                            <label htmlFor="exampleFormControlTextarea1" className='form-label mr-5'>Comentarios / Información adicional</label>
                            <textarea className='form-control' id="exampleFormControlTextarea1" rows="3" {...register("notes")}></textarea>
                        </div>
                    </div>
                </div>

                <div className='row mt-3 mb-3'>
                    <div className='col-md-12'>
                        <hr />
                    </div>
                    {state.dataOther && state.dataOther.status ? <></> : <div className='col-md-12 d-flex flex-row-reverse mb-3'>
                        <button className='btn btn-success-chanitos' type="submit">Guardar</button>
                    </div>}
                </div>


                {(state.dataOther && state.dataOther.status) || viewSuccess ? alertSuccess : <></>}
            </form>
        </>

    );
}

export default Other;