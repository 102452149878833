import React, { Fragment } from 'react';
const EducationFaqs = () => {
    return (
        <Fragment>
           <span className="faqs__tittle">Instrucciones</span>
           <ul className='faqs__list'>
                <li>Todas las preguntas con este símbolo (*) son obligatorias.</li>
                <li>Antes de pasar a otra sección recuerda guardar tu información.</li>
                <li>Empiece con su trayectoria académica más reciente.</li>
           </ul>
        </Fragment>
    );
}

export default EducationFaqs;