import React, { Fragment } from 'react';
import logo from './../../Assets/Images/logo.png';
function Header() {
    return (
        <Fragment>
            <div className='row header'>
                <div className='col-md-12 d-flex justify-content-center'>
                    <img src={logo} alt="frutaschanitos.com" className='logo'/>
                </div>
            </div>
        </Fragment>
    );
}

export default Header;