import React, { Fragment, useState, useEffect } from 'react';
import { uid } from 'uid';
import Cardexperience from "./profesional-experience-card";
function Listcardexperience(props) {
    const { listExperience, changeData } = props;
    const [listExperienceData, setListExperience] = useState([]);


    const handleClick = (uid) => {
        const newListExperience = listExperienceData.filter((experience) => experience.uid !== uid);
        changeData(newListExperience);
        setListExperience(newListExperience);

    };


    useEffect(() => {
        setListExperience(listExperience);
    });

    return (
        <Fragment>
            <div className="row">
                {listExperienceData.map((element) =>
                    <div className='col-md-4' key={uid()}>
                        <Cardexperience experience={element} childCliked={() => handleClick(element.uid)} ></Cardexperience>
                    </div>
                )}
            </div>
        </Fragment>
    );
}


export default Listcardexperience;