import React, { Fragment, useState, useEffect } from 'react';
import PersonaFaqs from './Personal/personal-data-faqs.component';
import EducationFaqs from "./Education/education.faqs.components";
import PersonalSection from './Personal/personal-data.component';
import ProfesionalExperience from './Experience/profesional-experience.component';
import EducationSection from './Education/education.component';
import Other from './Otros/other-form.component';
import Finish from './Finish/finish.component';
import Home from './Home/home.component';

function FormRecruitment(props) {
    const { screenActive } = props;
    const [screen, setScreen] = useState(0)

    useEffect(() => {
        setScreen(screenActive);
    });


    return (
        <Fragment>
            <div className="row">
                <div className="col-md-11">
                    {screen === 0 ? < Home ></Home> : <></>}
                    {screen === 1 ? < PersonalSection ></PersonalSection> : <></>}
                    {screen === 2 ? < ProfesionalExperience ></ProfesionalExperience> : <></>}
                    {screen === 3 ? < EducationSection ></EducationSection> : <></>}
                    {screen === 4 ? < Other ></Other> : <></>}
                    {screen === 5 ? < Finish ></Finish> : <></>}
                </div>

            </div>
        </Fragment >

    );
}

export default FormRecruitment;