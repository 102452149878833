import React, { Fragment, useState, useContext, useEffect } from 'react';
import { AppContext } from './../../../../Application/provaider';
import Lottie from "./../../../../Shared/Components/lottie.success.component";
import RecluitmentService from './../../Service/recluitment.service';
import PdfMakeService from './../../Service/pdf_create.service';
import { saveAs } from "file-saver";
const Finish = () => {
    const [state, setState] = useContext(AppContext);
    const server = 'https://chanitos.net/ftp/';
    //const server = 'http://localhost:5000/ftp/';
    const [document, setDocument] = useState('');
    const [loading, setLoading] = useState(false);
    const [documentCreate, setDocumentCreate] = useState(false);
    const documentCreateContent = <div className="alert alert-success" role="alert">
        <h5>Candidato registrado con exito</h5>
        <p>Recibirá pronto una respuesta de nuestro equipo de RH, le sugerimos estar atento a su correo electrónico.</p>
    </div>;
    const download = async () => {
        try {
            /*let request = {
                personal: {
                    nombre: "eduardo",
                    apellidoPaterno: "prieto",
                    apellidoMaterno: "islas",
                    direccion: "Prol 2 de abril",
                    ciudad: "México",
                    estado: "DIF",
                    cp: "56525",
                    telefono: "5567980945",
                    email: "eduardo@piensacode.com",
                    telefonoCelular: "5567980945",
                    fechaNacimiento: "1993-11-21"
                },
                experiencia: [
                    {
                        empresa: "Biometría aplicada",
                        ciudad: "México",
                        estado: "DIF",
                        telefono: "5541353517",
                        giroEmpresa: "Tecnología",
                        inicio: "2022-01",
                        fin: "2022-07",
                        nombrePuesto: "Programador Front End",
                        numeroJefeDirecto: "",
                        numeroJefeIndirecto: "",
                        salarioInicial: "35000",
                        salarioFinal: "35000",
                        beneficiosAdicionalesIniciales: [],
                        beneficiosAdicionalesFinales: [],
                        bonosIniciales: 0,
                        bonosFinales: 0,
                        valesDespensaInicial: "500",
                        valesDespensaFinales: "550",
                        otroInicial: "600",
                        otroFinal: "700",
                        nombreJefeDirecto: "Miguel Salazar",
                        puestoJefe: "Director de Innovación",
                        calificacionDesempenio: "3",
                        decicionRetiro: "1",
                        razonRetiro: "Buscar una mejor remuneración"
                    }
                ],
                educacion: [
                    {
                        actividadesExtra: []
                    }
                ],
                actividades: {
                    organizacionesMiembro: "Trabajo en freentec",
                    formacionesHabilidadesExito: "Programacion en circuitos electricos",
                    debilidades: "Ser inpuntual"
                },
                necesidadesProfesionales: {
                    disponibilidadCambioResidencia: true,
                    explicacion: ""
                },
                otros: {
                    empleadoLegal: false,
                    dispuestoConcretarLlamadas: true
                },
                fechaSolicitud: "",
                puestoAPostularse: "Programador front end",
                expectativaEconomica: "56000",
                notas: [
                    "Sin notas"
                ]
            };*/
            const dataForm = state;
            //const request = buildRequest(dataForm);
            /*const request = {
                "personal": {
                    "nombre": "Eduardo",
                    "apellidoPaterno": "Prieto",
                    "apellidoMaterno": "Islas",
                    "direccion": "Prolongacion 2 de abril",
                    "ciudad": "México",
                    "estado": "DIF",
                    "cp": 56500,
                    "telefono": 5541353517,
                    "email": "eduardo_211193@hotmail.com",
                    "telefonoCelular": 5541353517,
                    "fechaNacimiento": "21-11-1993"
                },
                "experiencia": [
                    {
                        "empresa": "PensionIsste",
                        "ciudad": "Mexico",
                        "estado": "DIF",
                        "telefono": 5541353518,
                        "giroEmpresa": "Servicios de gobierno",
                        "inicio": "22-06-2022",
                        "fin": "23-06-2022",
                        "nombrePuesto": "Lider de equipo en campo",
                        "numeroJefeDirecto": 5678902354,
                        "numeroJefeIndirecto": 5678902354,
                        "salarioInicial": 10200,
                        "salarioFinal": 11500,
                        "beneficiosAdicionalesIniciales": [
                            "Seguro de gastos medicos mayores",
                            "30 dias de aguinaldo",
                            "Plan dental"
                        ],
                        "beneficiosAdicionalesFinales": [
                            "Seguro de gastos medicos mayores",
                            "30 dias de aguinaldo",
                            "Plan dental",
                            "Viajes pagados"
                        ],
                        "bonosIniciales": 300,
                        "bonosFinales": 600,
                        "valesDespensaInicial": 100,
                        "valesDespensaFinales": 2000,
                        "otroInicial": 10,
                        "otroFinal": 20,
                        "nombreJefeDirecto": "Pedro Lopez de la Garza",
                        "puestoJefe": "Gerente de venta de platano",
                        "calificacionDesempenio": 1,
                        "decicionRetiro": 2,
                        "razonRetiro": "Cambio laboral por un mejor salario, ademas mi jefe no me dejaba salir a tiempo"
                    },
                    {
                        "empresa": "Santander",
                        "ciudad": "Mexico",
                        "estado": "DIF",
                        "telefono": 5541353518,
                        "giroEmpresa": "Servicios bancarios",
                        "inicio": "22-06-2022",
                        "fin": "23-06-2022",
                        "nombrePuesto": "Lider de equipo en area de Devops",
                        "numeroJefeDirecto": 5678902354,
                        "numeroJefeIndirecto": 5678902354,
                        "salarioInicial": 17000,
                        "salarioFinal": 19000,
                        "beneficiosAdicionalesIniciales": [
                            "Seguro de gastos medicos mayores",
                            "30 dias de aguinaldo",
                            "Plan dental"
                        ],
                        "beneficiosAdicionalesFinales": [
                            "Seguro de gastos medicos mayores",
                            "30 dias de aguinaldo",
                            "Plan dental",
                            "Bono anual"
                        ],
                        "bonosIniciales": 300,
                        "bonosFinales": 600,
                        "valesDespensaInicial": 100,
                        "valesDespensaFinales": 2000,
                        "otroInicial": 10,
                        "otroFinal": 20,
                        "nombreJefeDirecto": "Pedro Lopez de la Garza",
                        "puestoJefe": "Gerente de venta de platano",
                        "calificacionDesempenio": 1,
                        "decicionRetiro": 3,
                        "razonRetiro": "Cambio laboral por un mejor salario, ademas mi jefe no me dejaba salir a tiempo"
                    }
                ],
                "educacion": [
                    {
                        "nombreEscuela": "Universidad Autónoma del Estado de México",
                        "fechaInicio": "2012",
                        "fechaFin": "2016",
                        "titulo": "Ing. en computación",
                        "promedio": 9.8,
                        "actividadesExtra": [
                            "Curso de certificacion en JAVA SE",
                            "Curso de certiicación de SCRUM"
                        ]
                    },
                    {
                        "nombreEscuela": "Universidad Nacional Autónoma de México",
                        "fechaInicio": "2017",
                        "fechaFin": "2020",
                        "titulo": "Maestria en IA",
                        "promedio": 8.0,
                        "actividadesExtra": [
                            "Conferencia en tratamiento de datos",
                            "Curso de Machine Learning"
                        ]
                    }
                ],
                "actividades": {
                    "organizacionesMiembro": "Spotlife Santander México",
                    "formacionesHabilidadesExito": "He tomado cursos de administración de proyectos, se trabajar en equipo y lideriar equipos de trabajo enfocado a metodologías agiles.",
                    "debilidades": "Aveces no pongo mucha atencion a las necesidades personales de mis compañeros ya que me enfoco en mi trabajo."
                },
                "necesidadesProfesionales": {
                    "disponibilidadCambioResidencia": false,
                    "explicacion": ""
                },
                "otros": {
                    "empleadoLegal": true,
                    "dispuestoConcretarLlamadas": true
                },
                "fechaSolicitud": "",
                "puestoAPostularse": "Scrum master",
                "expectativaEconomica": 45000,
                "notas": ["Tengo disponibilidad de empezar de inmediato"]
            }*/
            setLoading(true);
            const request = {};
            const response = await RecluitmentService.createDocument(request);
            if (response.code === 'OPERATION_SUCCESS') {

                setDocument(response.mesagge);
                let url = server + response.mesagge;
                setTimeout(() => {
                    saveFile(url);
                    setLoading(false);
                    setDocumentCreate(true);
                    //window.open(url, '_blank');
                }, 3000);

            }

        } catch (error) {
            setLoading(false);
            console.log(error);
        }

    }

    const saveFile = (url) => {
        saveAs(
            url,
            "Curriculum.pdf"
        );
    };


    const buildRequest = (information) => {
        console.log(information);
        //Datos personales
        const personalInformation = information.dataPersonal.data;
        const dataPersonal = {
            nombre: personalInformation.name,
            apellidoP: personalInformation.firstName,
            apellidoM: personalInformation.secondName,
            fechaNacimiento: personalInformation.birthdate,
            direccion: personalInformation.direction,
            estado: personalInformation.state,
            ciudad: personalInformation.city,
            cp: personalInformation.cp,
            telefono: personalInformation.phoneHome,
            email: personalInformation.email,
            puestoDeseado: personalInformation.jobNeed,
            expectativaMensualBruto: personalInformation.money,
        }

        //Datos experiencia laboral
        const laboralInformation = information.dataExperience.data;
        let dataExperience = [];
        laboralInformation.forEach(element => {
            let aux_data = {
                nombreEmpresa: element.bussiness,
                giroEmpresa: element.bussinessRol,
                puestoOcupado: element.nameRol,
                ciudad: element.city,
                idEstado: element.state,
                telefonoEmpresa: element.phoneBussines ? element.phoneBussines : '',
                numeroPersonasCargoDirecto: element.numberPersonalDirect ? element.numberPersonalDirect : 0,
                numeroPersonasCargoIndirecto: element.numberPersonalOndirect ? element.numberPersonalOndirect : 0,
                fechaIngreso: element.beginJob,
                fechaEgreso: element.endJob,
                trabajoActual: element.actualJob,
                salarioBrutoInicial: element.salaryStart,
                salarioBrutoFinal: element.endSalary,
                bonoInicial: element.bonusBegin ? element.bonusBegin : 0,
                bonofinal: element.endBonus ? element.endBonus : 0,
                valesDespensaInicial: element.beginBonusMarket ? element.beginBonusMarket : 0,
                valesDespensaFinal: element.endBonusMarket ? element.endBonusMarket : 0,
                otrosInicio: element.beginOther ? element.beginOther : 0,
                otrosFinal: element.endOther ? element.endOther : 0,
                beneficiosadicionalesiniciales: element.listBenefitsStart ? element.listBenefitsStart : [],
                beneficiosadicionalesfinales: element.listBenefits ? element.listBenefits : [],
                puestoJefeInmediato: element.rolBoos ? element.rolBoos : '',
                nombreJefeInmediato: element.nameInmediateBoos ? element.nameInmediateBoos : '',
                idCalificacionDesempenioGeneral: element.calificationBoos,
                idMotivoDeRetiro: element.motivationEnd,
                razonRetiro: element.reason
            };
            dataExperience.push(aux_data);
        });

        //Datos educacion
        const educationInformation = information.dataEducation.data;
        let dataEducation = [];
        educationInformation.forEach(element => {
            let listElement = element.extraActivities ? element.extraActivities : [];
            let extra = [];
            listElement.forEach(element => {
                extra.push(element.data);
            });

            let aux_data = {
                escuela: element.school,
                titulo: element.title,
                fechaIngreso: element.beginSchool,
                fechaEgreso: element.endSchool,
                promedio: element.average,
                extracurricular: extra
            }
            dataEducation.push(aux_data);
        });


        //Datos Actividades
        const otherInformation = information.dataOther.data;
        const informacionadicional = {
            organizacionesRelevantes: otherInformation.organization ? otherInformation.organization : '',
            formacionesHabilidadesFortalezas: otherInformation.habilities ? otherInformation.habilities : '',
            flaquezaDebilidades: otherInformation.debilities ? otherInformation.debilities : '',
            disponibilidadCambiarResidencia: otherInformation.disponibility === 'true' ? true : false,
            razon: otherInformation.whynot ? otherInformation.whynot : '',
            empleadoLegal: otherInformation.work === 'true' ? true : false,
            concretarLlamadas: otherInformation.callboos === 'true' ? true : false,
            razonConcretarLlamadas: otherInformation.whynotBoos ? otherInformation.whynotBoos : '',
            comentarios: otherInformation.notes ? otherInformation.notes : ''
        }

        return {
            candidato: dataPersonal,
            experienciaLaboral: dataExperience,
            educacion: dataEducation,
            informacionadicional
        }
    }

    const progressCallbackMethod = (progress) => {
        if (progress <= 1) {
            setDocumentCreate(true);
            setLoading(false);
        }
    };

    const createDocument = async () => {
        try {
            setLoading(true);
            const dataForm = state;
            const request = buildRequest(dataForm);
            setTimeout(async () => {
                await PdfMakeService.createDocument(request, progressCallbackMethod);
                setDocumentCreate(true);
                setLoading(false);
            }, 3000);


        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    const saveCandidate = async () => {
        try {
            const dataForm = state;
            setLoading(true);
            const request = buildRequest(dataForm);
            const devServer = 'http://localhost:3000/api/hiring/save';
            //const prodServer = 'https://chanitos.net/api/hiring/save'
            const prodServer = 'http://18.190.48.126/api/hiring/save'
            const response = await fetch(
                prodServer,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(request)
                }
            );
            const data = await response.json();
            setDocumentCreate(true);
            setLoading(false);
        } catch (error) {
            console.log(error);
        }


    }

    return (
        <Fragment>
            <div className='row'>
                <div className="col-md-6">
                    <Lottie url="https://assets6.lottiefiles.com/packages/lf20_azmnf6vt.json" className="litty"></Lottie>
                </div>
                <div className="col-md-6">
                    <h3>Gracias por completar el formulario de historia profesional.</h3><br></br>
                    <p>En cuanto nuestro equipo de reclutamiento revise su postulación se pondrá en contacto con usted.</p>
                </div>
            </div>


            <div id="overlay" style={{ display: loading ? 'flex' : 'none' }}>
                <Lottie url="https://assets9.lottiefiles.com/private_files/lf30_vamrx5qj.json" className="litty"></Lottie>
            </div>
        </Fragment>
    );
}

export default Finish;