import React, { Fragment, useState, useContext, useEffect } from 'react';
import { uid } from 'uid';
import { useForm } from 'react-hook-form';
import { AppContext } from './../../../../Application/provaider';
import ListBeneits from "./profesional-experience-benefits.components";
import Listcardexperience from "./profesional-experience-listcard";
import ExperienceFaqs from "./experience-data-faqs.component";
import ListStates from "./../../Service/catalogs";
import ToolService from "./../../Service/toolService";
import axios from 'axios';


const ProfesionalExperience = () => {
    const [state, setState] = useContext(AppContext);
    const [viewSuccess, setViewSuccess] = useState(false);

    const [benefits, setBenefits] = useState(''); //State de beneficios
    const [benefitsFinal, setBenefitsFinal] = useState(''); //State de beneficios
    const [listBeneits, setListBeneits] = useState([]); //State de lista de beneficios
    const [listBeneitsFinal, setListBeneitsFinal] = useState([]); //State de lista de beneficios
    const { register, reset, getValues, formState: { errors }, handleSubmit } = useForm(); //Formulario
    const [listExperience, setListExperience] = useState([]);//Trabajos guardados

    const [actualJob, setActualJob] = useState(false);//Trabajo actual

    const [motivationEndInput, setMotivationEndInput] = useState(true);
    const [calificationInput, setCalificationInput] = useState(true);
    const baseUrl = 'https://chanitos.net/api';
    //const baseUrl = 'http://localhost:3000/api';

    const alertSuccess = <div className='alert alert-success text-center' role="alert">
        Tu información fue guardada con exito por favor pase a la pestaña de "Trayectoria Académica"
    </div>

    useEffect(() => {
        let defaultValue = [];
        const dataHold = state;
        if (dataHold && dataHold.dataExperience) {
            defaultValue = dataHold.dataExperience.data;
            setViewSuccess(dataHold.dataExperience.status);
        }
        setListExperience(defaultValue);
    }, [state]);

    const save = async () => {
        let state_copy = state;
        state_copy.dataExperience = {
            data: listExperience,
            status: true
        };
        let list = [];
        listExperience.forEach(experience => {
            list.push({
                nombreEmpresa: experience.bussiness,
                giroEmpresa: experience.bussinessRol,
                puestoOcupado: experience.nameRol,
                ciudad: experience.city,
                idEstado: ToolService.getIdState(experience.state),
                telefonoEmpresa: experience.phoneBussines,
                numeroPersonasCargoDirecto: experience.numberPersonalDirect,
                numeroPersonasCargoIndirecto: experience.numberPersonalOndirect,
                fechaIngreso: experience.beginJob,
                fechaEgreso: experience.endJob,
                trabajoActual: experience.actualJob,
                salarioBrutoInicial: experience.salaryStart,
                salarioBrutoFinal: experience.endSalary,
                bonoInicial: experience.bonusBegin,
                bonofinal: experience.endBonus,
                valesDespensaInicial: experience.beginBonusMarket,
                valesDespensaFinal: experience.endBonusMarket,
                otrosInicio: experience.beginOther,
                otrosFinal: experience.endOther,
                beneficiosadicionalesiniciales: experience.listBenefitsStart,
                beneficiosadicionalesfinales: experience.listBenefits,
                puestoJefeInmediato: experience.rolBoos,
                nombreJefeInmediato: experience.nameInmediateBoos,
                numeroJefeDirecto: experience.numberPersonalDirect,
                numeroJefeIndirecto: experience.numberPersonalOndirect,
                idCalificacionDesempenioGeneral: experience.calificationBoos,
                idMotivoDeRetiro: experience.motivationEnd,
                razonRetiro: experience.reason
            });
        });

        console.log('Datos del request');
        console.log(list);
        console.log('Id candidato');
        console.log(state_copy.idCandidate);
        const response = await setLaboralExperience({
            experience: list,
            idCandidate: state_copy.idCandidate
        });
        console.log(response);
        if (response.code == "OPERATION_SUCCESS") {
            setState(state_copy);
            console.log(state);
            setViewSuccess(true);
        } else {

        }


    }

    const setLaboralExperience = async (payload) => {
        try {
            const response = await axios.post(`${baseUrl}/hiring/save/candidate/laboral`, payload);
            return response.data;
        } catch (error) {
            return {
                code: "OPERATION_FAIL",
                mesagge: "Ocurrion un error al guardar la información"
            };
        }
    }



    //Inputs
    const onChangeHandler = e => {
        if (e.target.name === "newBenefits") {
            setBenefits(e.target.value);
        }

        if (e.target.name === "finalBenefits") {
            setBenefitsFinal(e.target.value);
        }

        if (e.target.name === "actualJob") {
            setActualJob(!actualJob);
            console.log(actualJob);
        }
    };


    //OnClick
    const handleClick = (event) => {
        event.preventDefault();

        if (event.target.name === 'agregarBenefitsInitial' && benefits !== '') {
            let listBeneits__aux = listBeneits;
            listBeneits__aux.push(benefits);
            setListBeneits(listBeneits__aux);
            setBenefits('');
        }

        if (event.target.name === 'agregarBenefitsEnd' && benefitsFinal !== '') {
            let listBeneits__aux = listBeneitsFinal;
            listBeneits__aux.push(benefitsFinal);
            setListBeneitsFinal(listBeneits__aux);
            setBenefitsFinal('');
        }

        if (event.target.name === "whitoutExperience") {
            continued();
        }
    };


    const onSubmit = (data) => {
        generateSecction(data, listBeneits, listBeneitsFinal);
    }


    const generateSecction = (dataForm, beginBeneits, listBenefits) => {
        const valueMotivation = getValues('motivationEnd');
        const valuerCalification = getValues('calificationBoos');

        if (valueMotivation === '0' || valuerCalification === '0') {
            setMotivationEndInput(false);
            setCalificationInput(false);
        } else {
            setMotivationEndInput(true);
            setCalificationInput(true);
            let stateListExperience = listExperience;
            let experience = dataForm;
            experience.listBenefits = listBenefits;
            experience.listBenefitsStart = beginBeneits;
            experience.actualJob = actualJob;
            experience.uid = uid();
            console.log(experience);
            stateListExperience.push(experience);


            setListExperience(stateListExperience);
            setListBeneitsFinal([]);
            setListBeneits([]);
            reset();
        }


    }//Generar seccion de empleos

    const continued = () => {
        console.log(listExperience);
    }

    //Constantes
    const ListEstates = ListStates;

    const ListQuantification = [
        { value: 0, label: "Seleccione una opción" },
        { value: 1, label: "Excelente" },
        { value: 2, label: "Muy bien" },
        { value: 3, label: "Bien" },
        { value: 4, label: "Satisfactorio" },
        { value: 5, label: "Malo" }
    ];

    const ListMotivationEnd = [
        { value: 0, label: "Seleccione una opción" },
        { value: 1, label: "100% mi Decisión" },
        { value: 2, label: "Decisión mutua" },
        { value: 3, label: "100% decisión de la empresa (me despidieron)" },
        { value: 4, label: "Otra circunstancia" }
    ];




    return (
        <Fragment>
            <form autoComplete='false' onSubmit={handleSubmit(onSubmit)}>

                <div className='row mb-3'>
                    <div className='col-md-12 mb-3'>
                        <h4 className='tittleSection'>Experiencia Laboral</h4>

                    </div>
                    <ExperienceFaqs></ExperienceFaqs>
                    <hr />
                </div>

                <div className='row mb-4 mt-4'>
                    <div className='col-md-4'>
                        <label htmlFor="bussiness" className='form-label label-required'>Empresa</label>
                        <input type="text" className={errors.bussiness ? 'form-control is-invalid' : 'form-control'} id="bussiness"  {...register("bussiness", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.bussiness && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="bussinessRol" className='form-label label-required'>Giro de la empresa</label>
                        <input type="text" className={errors.bussinessRol ? 'form-control is-invalid' : 'form-control'} id="bussinessRol"  {...register("bussinessRol", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.bussinessRol && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-4'>
                        <label htmlFor="nameRol" className='form-label label-required'>Nombre del puesto</label>
                        <input type="text" className={errors.nameRol ? 'form-control is-invalid' : 'form-control'} id="nameRol"  {...register("nameRol", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.nameRol && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className='col-md-4'>
                        <label htmlFor="state" className='form-label label-required'>Estado</label>
                        <select className={errors.state ? 'form-control is-invalid' : 'form-control'} id="state"  {...register('state', { required: true })} >
                            {ListEstates.map((estado, index) => (
                                <option key={index} value={estado.value}>{estado.label}</option>
                            ))}

                        </select>

                        <div className="invalid-feedback d-flex">
                            {errors.state && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="city" className='form-label label-required'>Ciudad</label>
                        <input type="text" className={errors.city ? 'form-control is-invalid' : 'form-control'} id="city"  {...register("city", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.city && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="phoneBussines" className='form-label'>Teléfono de la empresa</label>
                        <input type="number" className={errors.phoneBussines ? 'form-control is-invalid' : 'form-control'} id="phoneBussines"  {...register("phoneBussines")} />
                        <div className="invalid-feedback d-flex">
                            {errors.phoneBussines && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className='col-md-4'>
                        <label htmlFor="numberPersonalDirect" className='form-label'>Número de Personal directo a cargo:</label>
                        <input type="number" className={errors.numberPersonalDirect ? 'form-control is-invalid' : 'form-control'} id="numberPersonalDirect"  {...register("numberPersonalDirect")} />
                        <div className="invalid-feedback d-flex">
                            {errors.numberPersonalDirect && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="numberPersonalOndirect" className='form-label'>Número de Personal indirecto a cargo:</label>
                        <input type="number" className={errors.numberPersonalOndirect ? 'form-control is-invalid' : 'form-control'} id="numberPersonalOndirect"  {...register("numberPersonalOndirect")} />
                        <div className="invalid-feedback d-flex">
                            {errors.numberPersonalOndirect && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className='col-md-4'>
                        <label htmlFor="beginJob" className='form-label label-required'>Fecha de ingreso</label>
                        <input type="date" className={errors.beginJob ? 'form-control is-invalid' : 'form-control'} id="beginJob"  {...register("beginJob", { required: true })} />
                        <div className="invalid-feedback d-flex">
                            {errors.beginJob && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="endJob" className={actualJob ? 'form-label' : 'form-label label-required'}>Fecha de salida</label>
                        <input type="date" className={errors.endJob ? 'form-control is-invalid' : 'form-control'} id="endJob"  {...register("endJob", { required: true, disabled: actualJob })} />
                        <div className="invalid-feedback d-flex">
                            {errors.endJob && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <label htmlFor="endJob" className='form-label'>Empleo actual</label>
                        <div className="form-check form-switch">
                            <input className="form-check-input" type="checkbox" id="actualJob" {...register("actualJob", { required: false })} onChange={onChangeHandler} />
                        </div>
                    </div>
                </div>


                <div className="alert alert-secondary text-center mt-4 mb-4" role="alert">
                    Remuneraciones
                </div>



                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="salaryStart" className='form-label label-required'>Salario Mensual MXN Inicial Bruto</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.salaryStart ? 'form-control is-invalid' : 'form-control'} id="salaryStart" {...register("salaryStart", { required: true })} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.salaryStart && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor="endSalary" className='form-label label-required'>Salario Mensual MXN Final Bruto</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.endSalary ? 'form-control is-invalid' : 'form-control'} id="endSalary" {...register("endSalary", { required: true })} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.endSalary && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="bonusBegin" className='form-label'>Bonos iniciales</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.bonusBegin ? 'form-control is-invalid' : 'form-control'} id="bonusBegin" {...register("bonusBegin")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.bonusBegin && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor="endBonus" className='form-label'>Bonos finales</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.endBonus ? 'form-control is-invalid' : 'form-control'} id="endBonus" {...register("endBonus")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.endBonus && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="beginBonusMarket" className='form-label'>Vales de despensa inicial</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.beginBonusMarket ? 'form-control is-invalid' : 'form-control'} id="beginBonusMarket" {...register("beginBonusMarket")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.beginBonusMarket && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor="endBonusMarket" className='form-label'>Vales de despensa final</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.endBonusMarket ? 'form-control is-invalid' : 'form-control'} id="endBonusMarket" {...register("endBonusMarket")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.endBonusMarket && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>


                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="beginOther" className='form-label'>Otros inicio</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.beginOther ? 'form-control is-invalid' : 'form-control'} id="beginOther" {...register("beginOther")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.beginOther && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor="endOther" className='form-label'>Otros final</label>
                        <div className="input-group mb-3">
                            <span className="input-group-text target-form">$</span>
                            <input type="number" className={errors.endOther ? 'form-control is-invalid' : 'form-control'} id="endOther" {...register("endOther")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.endOther && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>

                <div className="row mb-4">
                    <div className="col-md-6">
                        <label htmlFor="newBenefits" className='form-label'>Beneficios adicionales iniciales</label>
                        <input
                            className='form-control'
                            type="text"
                            name="newBenefits"
                            onChange={onChangeHandler}
                            value={benefits}
                        />
                        <button onClick={handleClick} className={benefits ? 'btn btn-success-chanitos mt-2 mb-2' : 'btn btn-success-chanitos disabled mt-2 mb-2'} name='agregarBenefitsInitial'>Agregar</button>
                        <ListBeneits benefits={listBeneits}></ListBeneits>
                    </div>
                    <div className="col-md-6">
                        <label htmlFor="finalBenefits" className='form-label'>Beneficios adicionales finales</label>
                        <input
                            className='form-control'
                            type="text"
                            name="finalBenefits"
                            onChange={onChangeHandler}
                            value={benefitsFinal}
                        />
                        <button onClick={handleClick} className={benefitsFinal ? 'btn btn-success-chanitos mt-2 mb-2' : 'btn btn-success-chanitos disabled mt-2 mb-2'} name='agregarBenefitsEnd'>Agregar</button>
                        <ListBeneits benefits={listBeneitsFinal}></ListBeneits>
                    </div>
                </div>


                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="nameInmediateBoos" className='form-label'>Nombre de jefe inmediato</label>
                        <div className="input-group mb-3">

                            <input type="text" className={errors.nameInmediateBoos ? 'form-control is-invalid' : 'form-control'} id="nameInmediateBoos" {...register("nameInmediateBoos")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.nameInmediateBoos && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                    <div className='col-md-6'>
                        <label htmlFor="rolBoos" className='form-label'>Puesto de su jefe</label>
                        <div className="input-group mb-3">
                            <input type="text" className={errors.rolBoos ? 'form-control is-invalid' : 'form-control'} id="rolBoos" {...register("rolBoos")} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.rolBoos && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>

                </div>


                <div className="row mb-4">
                    <div className='col-md-6'>
                        <label htmlFor="calificationBoos" className='form-label label-required'>¿Qué calificación estima que le daría este jefe respecto a su desempeño general? (Marque con una X en el recuadro indicado).</label>
                        <select className={errors.calificationBoos || !calificationInput ? 'form-control is-invalid' : 'form-control'} id="calificationBoos"  {...register('calificationBoos', { required: true })} >
                            {ListQuantification.map((quantiication, index) => (
                                <option key={index} value={quantiication.value}>{quantiication.label}</option>
                            ))}

                        </select>
                    </div>
                    <div className='col-md-6'>
                        <label htmlFor="motivationEnd" className='form-label label-required'>Si se retiró de la empresa, por favor indique su motivo de hacerlo. (Marque con una X en el recuadro indicado).</label>
                        <select className={errors.motivationEnd || !motivationEndInput ? 'form-control is-invalid' : 'form-control'} id="motivationEnd"  {...register('motivationEnd', { required: true })} >
                            {ListMotivationEnd.map((motivation, index) => (
                                <option key={index} value={motivation.value}>{motivation.label}</option>
                            ))}
                        </select>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className='col-md-12'>
                        <label htmlFor="reason" className='form-label label-required'>Razón(es) por el cual se retira de la empresa por favor explíquela</label>
                        <div className="input-group mb-3">
                            <input type="text" className={errors.reason ? 'form-control is-invalid' : 'form-control'} id="reason" {...register("reason", { required: true })} />
                        </div>
                        <div className="invalid-feedback d-flex">
                            {errors.reason && <small className="text-error-input">Este campo es requerido</small>}
                        </div>
                    </div>
                </div>



                <div className='row mt-3 mb-4'>
                    <div className='col-md-12'>
                        <hr />
                    </div>
                    <div className='col-md-12 d-flex justify-content-end'>
                        {listExperience.length < 8 ? <button type="submit" className='btn btn-success-chanitos' style={{ marginLeft: "1rem" }}>Agregar</button> : <></>}
                    </div>
                </div>

            </form>


            <Listcardexperience listExperience={listExperience} changeData={(data) => setListExperience(data)}></Listcardexperience>
            {listExperience.length > 0 ? <div className='row mt-3'>
                <div className='col-md-12'>
                    <hr />
                </div>

                {state.dataExperience && state.dataExperience.status ? <></> : <div className='col-md-12 d-flex flex-row-reverse mb-3'>
                    <button className='btn btn-success-chanitos' onClick={() => save()}>Guardar</button>
                </div>}

            </div> : <></>}
            {(state.dataExperience && state.dataExperience.status) || viewSuccess ? alertSuccess : <></>}
        </Fragment>
    );
}

export default ProfesionalExperience;