import React, { Fragment } from 'react';
import Profile from "../../../../Shared/Components/lottie.profile.component";
function Cardexperience(props) {
    const { experience, childCliked } = props;
    return (
        <Fragment>
            <div className="card experience-card">
                <div className="card-body experience-card-body">
                    <Profile></Profile>
                    <h5 className="card-title">{experience.bussiness}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{experience.nameRol}</h6>
                    <p className="card-text">{experience.reason}</p>
                    <span className="experience-card-delete" onClick={() => childCliked(experience.uid)}>Eliminar</span>
                </div>
            </div>
        </Fragment>
    );
}

export default Cardexperience;