import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { logo, imageActivities } from "./pdf_image.service";
import ListStates from "./catalogs";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const createTemplete = async (data) => {

    let docDefinition = {
        pageSize: "LETTER", //Tamaño de pagina
        pageMargins: [60, 80, 60, 40], //Margenes de la pagina
        header: header(),
        //Body
        content: bodyDocument(data),

        defaultStyle: {
            fontSize: 9,
            lineHeight: 1.5,
        },

        footer: getFooter(),

        styles: getStyle(),
    };
    return docDefinition;
}



//Section document
const header = () => {
    return [
        {
            image: logo,
            width: 50,
            alignment: "right",
            margin: [40, 15, 0, 0],
        },
    ]
}

const bodyDocument = (dataTemplate) => {
    return [
        {
            image: logo,
            width: 500,
            alignment: "center",
            pageBreak: "after",
        },
        'Somos un productor, comercializador, madurador, distribuidor y exportador verticalmente integrado de bananas en México. Nuestro por qué es: "Cultivamos energía natural para que alcances tus sueños". Cada caja vendida de plátano es un sueño logrado.\n\n',
        "En Chanitos desarrollamos una agricultura responsable porque respetamos el entorno, la cultura y el medio ambiente, creando fincas preocupadas por todo lo que las rodea. En conjunto, las comunidades y los trabajadores, colaboramos para lograr la mejor banana del mundo y así, todos tengamos la energía natural para alcanzar nuestros sueños. Porque si todos los días la gente tiene la energía para hacer lo que le gusta, entonces podrán dar lo mejor de ellos.\n\n",
        "Nuestra promesa a los clientes es que somos la banana nutritiva que da la energía para que hagas lo que más te gusta y te apasiona de manera divertida.\n\n",
        "Nuestros valores son las reglas del juego y determinan cómo hacemos las cosas:\n\n",
        {
            ul: [
                "Chanitos somos Todos",
                "Somos energía natural",
                "Soñamos despiertos y en grande",
                "Buscamos cómo sí",
                "Hacemos más con menos",
                'Sólo jugadores "A"',
                "Predicamos con el ejemplo",
            ],
            margin: [20, 0, 0, 0],
        },
        "\n\n¿Qué hacemos como Grupo Chanitos? Enfocamos nuestros esfuerzos en 5 actividades clave:\n\n",
        {
            image: imageActivities,
            width: 500,
        },
        "\n\nPor lo tanto, sembramos energía natural que te mueve. En Chanitos cuidamos que el producto mantenga todas sus propiedades naturales; nuestra pasión es cultivar las mejores bananas del mundo.\n\n",
        "Cuidamos el proceso para entregar las mejores bananas de principio a fin. Tenemos un compromiso total de calidad con la cadena; desde su origen en nuestras fincas, el empaque, transporte, maduración y la entrega a clientes finales.\n\n",
        "Cuidamos a las comunidades porque generamos empleos y las desarrollamos. Llenamos de energía a nuestros colaboradores y familias ayudando a mejorar el entorno en el que viven y generando trabajos para que todos logremos hacer lo que más nos gusta día a día.\n\n",
        "Empacamos las mejores marcas, nuestras y de otros clientes, con especial cuidado. Y exportamos de acuerdo a los criterios de calidad y especificaciones en cualquier parte del mundo.\n\n",
        {
            text: "Chanitos tiene grandes sueños que cumplir y uno de ellos es un crecimiento sostenible de la empresa. Para ello reclutamos al mejor talento que siga nuestras creencias y nuestros valores. Favor de llenar el formulario de historial profesional para iniciar el proceso de selección y reclutamiento.",
            pageBreak: "after",
        },

        //Page Formulario
        "Esta información no será la única base para las decisiones de contratación. No se le está solicitando que facilite información que sea prohibida ante la ley federal, estatal o local.\n\n",

        getPersonal(dataTemplate),


        {
            text: "I. EXPERIENCIA PROFESIONAL: (Por favor comience por su puesto actual o el más reciente).\n\n",
            style: "negrita",
        },
        getLaboralExperience(dataTemplate),

        //Page education
        {
            text: "III. EDUCACIÓN:\n\n",
            style: "negrita",
        },
        {
            text: "Universidad y/o Maestría:\n\n",
            style: "negrita",
            alignment: "center",
        },
        getEducation(dataTemplate),
        {
            text: "",
            pageBreak: "after",
        },
        //Page Actividades
        {
            text: "IV. ACTIVIDADES:\n\n",
            style: "negrita",
        },
        getActivities(dataTemplate),
        //Necesidades profesionales
        {
            text: "\n\nV. NECESIDADES PROFESIONALES:\n\n",
            style: "negrita",
        },
        getNeedProfetionals(dataTemplate),
        //Otros
        {
            text: "\n\nVI. OTRO:\n\n",
            style: "negrita",
        },
        getOther(dataTemplate),
        //Notas
        {
            text:
                dataTemplate.notas.length > 0
                    ? "Comentarios/Información Adicional:\n\n"
                    : "Sin Comentarios/Información Adicional",
            style: "negrita",
            alignment: dataTemplate.notas.length > 0 ? "left" : "center",
            margin: [0, 20, 0, 0],
        },
        dataTemplate.notas.length > 0 ? getNotes(dataTemplate) : "",
    ]
}

const getPersonal = (data) => {
    return [
        {
            style: "tableExample",
            table: {
                headerRows: 1,
                widths: [150, 150, 150],
                body: [
                    [
                        { text: "Nombre", style: "tableHeader" },
                        { text: "Apelllido Paterno", style: "tableHeader" },
                        { text: "Apellido Materno", style: "tableHeader" },
                    ],
                    [
                        `${data.personal.nombre}`,
                        `${data.personal.apellidoPaterno}`,
                        `${data.personal.apellidoMaterno}`,
                    ],
                    [
                        { text: "Dirección de domicilio", style: "tableHeader" },
                        { text: "Ciudad", style: "tableHeader" },
                        { text: "Estado", style: "tableHeader" },
                    ],
                    [
                        `${data.personal.direccion}`,
                        `${data.personal.ciudad}`,
                        `${getState(data.personal.estado)}`,
                    ],
                    [
                        { text: "C.P", style: "tableHeader" },
                        { text: "Clave lada + no. telefónico", style: "tableHeader" },
                        {
                            text: "Dirección de correo electrónico",
                            style: "tableHeader",
                        },
                    ],
                    [
                        `${data.personal.cp}`,
                        `${data.personal.telefono}`,
                        `${data.personal.email}`,
                    ],
                    [
                        { text: "Teléfono celular", style: "tableHeader" },
                        {
                            text: "Fecha Nacimiento (día/mes/año)",
                            style: "tableHeader",
                        },
                        { text: "Fecha Solicitud", style: "tableHeader" },
                    ],
                    [
                        `${data.personal.telefonoCelular}`,
                        `${data.personal.fechaNacimiento}`,
                        `${getToday()}`,
                    ],
                ],
            },
            layout: "noBorders",
        },

        {
            alignment: "justify",
            columns: [
                {
                    text: "\n\nPuesto al que desea postularse:",
                    style: "negrita",
                },
                {
                    text: "\n\nExpectativa económica MXM Mensual Bruto:",
                    style: "negrita",
                },
            ],
        },

        {
            alignment: "justify",
            columns: [
                {
                    text: data.puestoAPostularse,
                    decoration: "underline",
                },
                {
                    text: formatCash(data.expectativaEconomica),
                    decoration: "underline",
                },
            ],
            pageBreak: "after",
        },
    ];
}

const getLaboralExperience = (dataExperience) => {
    let data = dataExperience.experiencia;
    let experience = [];
    let aux_experience = null;
    data.forEach((element, index) => {
        aux_experience = [
            {
                text: `${index + 1} )\n\n`,
            },
            {
                style: "tableExample",
                table: {
                    margin: [0, 15],
                    widths: [150, 150, 150],
                    body: [
                        //Empresa, Ciudad y Estado
                        [
                            { text: "Empresa", style: "tableHeader" },
                            { text: "Ciudad", style: "tableHeader" },
                            { text: "Estado", style: "tableHeader" },
                        ],
                        [
                            {
                                text: element.empresa,
                                alignment: "left",
                            },
                            {
                                text: element.ciudad,
                                alignment: "left",
                            },
                            {
                                text: getState(element.estado),
                                alignment: "left",
                            },
                        ],

                        //Teléfono y  Giro de la empresa
                        [
                            { text: "Teléfono", style: "tableHeader" },
                            { text: "Giro de la empresa", style: "tableHeader" },
                            {},
                        ],
                        [
                            {
                                text: element.telefono,
                                alignment: "left",
                            },
                            {
                                text: element.giroEmpresa,
                                alignment: "left",
                            },
                            {},
                        ],

                        //Fecha de inicio y Fecha de término
                        [
                            { text: "Fecha de inicio", style: "tableHeader" },
                            { text: "Fecha de término", style: "tableHeader" },
                            {},
                        ],
                        [
                            {
                                text: element.inicio,
                                alignment: "left",
                            },
                            {
                                text: element.fin,
                                alignment: "left",
                            },
                            {},
                        ],

                        //Nombre de su puesto, Numero de Personal: Numero de Personal Nombre de su puesto directo a cargo:
                        [
                            { text: "Nombre de su puesto", style: "tableHeader" },
                            {
                                text: "Numero de Personal directo a cargo",
                                style: "tableHeader",
                            },
                            {
                                text: "Numero de Personal indirecto a cargo",
                                style: "tableHeader",
                            },
                        ],
                        [
                            {
                                text: element.nombrePuesto,
                                alignment: "left",
                            },
                            {
                                text: element.numeroJefeDirecto,
                                alignment: "left",
                            },
                            {
                                text: element.numeroJefeIndirecto,
                                alignment: "left",
                            },
                        ],
                    ],
                },

                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                    },
                },
            },
            {
                style: "tableExample",
                margin: [0, 30],
                table: {
                    headerRows: 1,
                    widths: [230, 230],
                    body: [
                        [
                            { text: "Salario Mensual Inicial Bruto", style: "tableHeader" },

                            { text: "Salario Mensual Final Bruto", style: "tableHeader" },
                        ],
                        [
                            {
                                text: `${formatCash(element.salarioInicial)}`,
                                alignment: "left",
                            },
                            {
                                text: `${formatCash(element.salarioFinal)}`,
                                alignment: "left",
                            },
                        ],
                        [
                            { text: "Beneficios Adicionales", style: "tableHeader" },
                            { text: "Beneficios Adicionales", style: "tableHeader" },
                        ],
                        [
                            {
                                ul: element.beneficiosAdicionalesIniciales,
                            },
                            {
                                ul: element.beneficiosAdicionalesFinales,
                            },
                        ],
                        [
                            { text: "Bonos Iniciales", style: "tableHeader" },
                            { text: "Bonos Finales", style: "tableHeader" },
                        ],
                        [
                            {
                                text: `${formatCash(element.bonosIniciales)}`,
                                alignment: "left",
                            },
                            {
                                text: `${formatCash(element.bonosFinales)}`,
                                alignment: "left",
                            },
                        ],

                        [
                            { text: "Vales de despensa (Inicio)", style: "tableHeader" },

                            { text: "Vales de despensa (Final)", style: "tableHeader" },
                        ],

                        [
                            {
                                text: `${formatCash(element.valesDespensaInicial)}`,
                                alignment: "left",
                            },
                            {
                                text: `${formatCash(element.valesDespensaFinales)}`,
                                alignment: "left",
                            },
                        ],

                        [
                            {
                                text: "Otros beneficios monetarios (Inicial)",
                                style: "tableHeader",
                            },

                            {
                                text: "Otros beneficios monetarios (Final)",
                                style: "tableHeader",
                            },
                        ],

                        [
                            {
                                text: `${formatCash(element.otroInicial)}`,
                                alignment: "left",
                            },
                            {
                                text: `${formatCash(element.otroFinal)}`,
                                alignment: "left",
                            },
                        ],

                        [
                            { text: "Nombre de jefe inmediato", style: "tableHeader" },

                            { text: "Puesto de su jefe", style: "tableHeader" },
                        ],
                        [
                            {
                                text: `${element.nombreJefeDirecto}`,
                                alignment: "left",
                            },
                            {
                                text: `${element.puestoJefe}`,
                                alignment: "left",
                            },
                        ],
                    ],
                },

                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                    },
                },
            },
            {
                style: "tableExample",
                margin: [0, 15],
                table: {
                    headerRows: 1,
                    widths: [350, 100],
                    body: [
                        [
                            {
                                text: "¿Qué calificación estima que le daría este jefe respecto a su desempeño general?",
                                style: "tableHeader",
                            },
                            {
                                text: `${opinionBoos(element.calificacionDesempenio)}`,
                                alignment: "left",
                                decoration: "underline",
                            },
                        ],
                        [
                            {
                                text: "Si se retiró de la empresa, por favor indique su motivo para hacerlo.",
                                style: "tableHeader",
                            },
                            {
                                text: `${motivoRetiro(element.decicionRetiro)}`,
                                alignment: "left",
                                decoration: "underline",
                            },
                        ],
                    ],
                },
                //layout: "noBorders",
            },
            {
                text: `Razón(es) por el cual se retira de la empresa por favor explíquela: \n`,
                style: "negrita",
            },
            {
                text: `${element.razonRetiro}`,
                pageBreak: "after",
            },
        ];
        experience.push(aux_experience);
    });
    return experience;
}

const getEducation = (data) => {
    const dataEducation = data.educacion;
    let response = [];
    dataEducation.forEach((element) => {
        let response_aux = [
            {
                style: "tableExample",
                margin: [0, 15],
                table: {
                    headerRows: 1,
                    widths: [250, 250],
                    body: [
                        [
                            {
                                text: "Instituto",
                                style: "tableHeader",
                                alignment: "center",
                                colSpan: 2,
                            },
                            {},
                        ],
                        [
                            {
                                text: element.nombreEscuela,
                                alignment: "center",
                                colSpan: 2,
                            },
                            {},
                        ],
                        [
                            {
                                text: "Fecha de ingreso",
                                style: "tableHeader",
                                alignment: "center",
                            },
                            {
                                text: "Fecha de egreso",
                                style: "tableHeader",
                                alignment: "center",
                            },
                        ],
                        [
                            { text: element.fechaInicio, alignment: "center" },
                            { text: element.fechaFin, alignment: "center" },
                        ],
                        [
                            {
                                text: "Titulo",
                                style: "tableHeader",
                                alignment: "center",
                                colSpan: 2,
                            },
                            {},
                        ],
                        [{ text: element.titulo, alignment: "center", colSpan: 2 }, {}],
                        [
                            {
                                text: "Promedio",
                                style: "tableHeader",
                                alignment: "center",
                                colSpan: 2,
                            },
                            {},
                        ],
                        [{ text: element.promedio, alignment: "center", colSpan: 2 }, {}],
                        [
                            {
                                text: "Actividades extracorriculares",
                                style: "tableHeader",
                                alignment: "center",
                                colSpan: 2,
                            },
                            {},
                        ],
                        [{ ul: element.actividadesExtra, colSpan: 2, alignment: "center" }, {}],
                    ],
                },
                layout: {
                    fillColor: function (rowIndex, node, columnIndex) {
                        return rowIndex % 2 === 0 ? "#CCCCCC" : null;
                    },
                },
                //layout: "noBorders",
            },
        ];
        response.push(response_aux);
    });
    return response;
}

const getActivities = (data) => {
    let dataActivities = data.actividades;
    return [
        {
            text: "Organizaciones profesionales o relevantes al empleo de las que es miembro.\n\n",
            style: "negrita",
        },
        { text: dataActivities.organizacionesMiembro + "\n\n" },
        {
            text: "¿Qué formaciones, habilidades, y fortalezas le ayudarán a tener éxito en este empleo?\n\n",
            style: "negrita",
        },
        { text: dataActivities.formacionesHabilidadesExito + "\n\n" },
        {
            text: "¿Cuáles son sus flaquezas o debilidades que habría que mejorar?\n\n",
            style: "negrita",
        },
        { text: dataActivities.debilidades + "\n\n" },
    ];
}

const getNeedProfetionals = (dataProfesional) => {
    const data = dataProfesional.necesidadesProfesionales;
    return {
        style: "tableExample",
        table: {
            headerRows: 1,
            widths: [300, 100],
            body: [
                [
                    {
                        text: `¿Tiene disponibilidad de cambiar de residencia?`,
                        style: "negrita",
                    },
                    {
                        text: `${data.disponibilidadCambioResidencia ? "SI" : "NO"}`,
                        decoration: "underline",
                    },
                ],
                [{ text: `${data.explicacion}`, colSpan: 2 }, {}],
            ],
        },
        layout: "noBorders",
    };
}

const getOther = (dataOther) => {
    const data = dataOther.otros;
    return {
        style: "tableExample",
        table: {
            headerRows: 1,
            widths: [400, 100],
            body: [
                [
                    {
                        text: `¿Puede trabajar como empleado legal dentro La República Mexicana?`,
                        style: "negrita",
                    },
                    {
                        text: `${data.empleadoLegal ? "SI" : "NO"}`,
                        decoration: "underline",
                    },
                ],
                [
                    {
                        text: `¿Estaría dispuesto a concretar las llamadas de referencia con los jefes que haya tenido en la última década, como último paso antes de una oferta de empleo?`,
                        style: "negrita",
                    },
                    {
                        text: `${data.dispuestoConcretarLlamadas ? "SI" : "NO"}`,
                        decoration: "underline",
                    },
                ],
            ],
        },
        layout: "noBorders",
    };
}

const getNotes = (dataNotes) => {
    const data = dataNotes.notas;
    let body = [];
    data.forEach((element) => {
        let aux_body = [
            {
                text: `${element}`,
                alignment: "left",
            },
        ];
        body.push(aux_body);
    });
    return {
        style: "tableExample",
        margin: [0, 0],
        table: {
            headerRows: 0,
            widths: [320],
            body,
        },
        //layout: "noBorders",
    };
}



const getFooter = () => {
    return {
        columns: [
            {
                text: "¡Cultivamos Energía… Cosechamos Sueños!",
                alignment: "center",
            },
        ],
    }
}


//tools
const getStyle = () => {
    return {
        header: {
            fontSize: 18,
            bold: true,
        },
        subheader: {
            fontSize: 15,
            bold: true,
        },
        subtitulo: {
            fontSize: 12,
            bold: true,
        },
        tableHeader: {
            bold: true,
            fontSize: 9,
        },
        negrita: {
            bold: true,
        },
    };
}

const getState = (data) => {

    const ListEstates = ListStates;
    let state = "";
    ListEstates.forEach((element) => {
        if (element.value === data) {
            state = element.label;
        }
    });
    return state;

}

const getToday = () => {
    var date = new Date();
    const today = formatDate(date);
    return today;
}

const formatDate = (date) => {
    const months = [
        "JAN",
        "FEB",
        "MAR",
        "APR",
        "MAY",
        "JUN",
        "JUL",
        "AUG",
        "SEP",
        "OCT",
        "NOV",
        "DEC",
    ];
    let formatted_date =
        date.getDate() + "-" + months[date.getMonth()] + "-" + date.getFullYear();
    return formatted_date;
}

const formatCash = (value) => {
    if (value) {
        let cifras = 3;
        let inputNum;
        let inputSeparado;

        inputNum = value ? value.toString() : "";
        inputSeparado = inputNum.split(".");
        if (!inputSeparado[1]) {
            inputSeparado[1] = "00";
        }
        let separados = [];
        if (inputSeparado[0].length > cifras) {
            let uno = inputSeparado[0].length % cifras;
            if (uno === 0) {
                separados = [];
            } else {
                separados = [inputSeparado[0].substring(0, uno)];
            }
            let tamanio = inputSeparado[0].length;
            let posiciones = tamanio / cifras;
            for (let i = 0; i < posiciones; i++) {
                let pos = i * cifras + uno;
                if (pos < tamanio) {
                    separados.push(inputSeparado[0].substring(pos, pos + 3));
                }
            }
        } else {
            separados = [inputSeparado[0]];
        }
        return `$${separados.join()}.${inputSeparado[1]}`;
    } else {
        return "$0.00";
    }
}

const opinionBoos = (calification) => {
    let response = "";
    switch (calification) {
        case 1:
        default:
            response = "Excelente";
            break;
        case 2:
            response = "Muy bien";
            break;
        case 3:
            response = "Bien";
            break;
        case 4:
            response = "Satisfactorio";
            break;
        case 5:
            response = "malo";
            break;
    }
    return response;
}

const motivoRetiro = (calificationRetiro) => {
    let response = "";
    switch (calificationRetiro) {
        case 1:
        default:
            response = "100% mi decisión";
            break;
        case 2:
            response = "Decisión mutua";
            break;
        case 3:
            response = "100% decisión de la empresa (Me despidieron)";
            break;
    }
    return response;
}

const PdfMakeService = {
    createDocument: async (docInformation, progressFuntion) => {
        const template = await createTemplete(docInformation);
        const pdf = pdfMake.createPdf(template);
        await pdf.download('Curriculum');
    }
};



export default PdfMakeService;
