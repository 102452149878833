import React, { Fragment } from 'react';
const PersonaFaqs = () => {
    return (
        <Fragment>
            <div className="col-md-12 mb-3">
                <span className="faqs__tittle">Instrucciones</span>
                <ul className='faqs__list'>
                    <li>Las preguntas con este simbolo (*) son obligatorias.</li>
                    <li>Guarda las respuesta de esta seccion antes de pasar a la siguiente</li>
                    <li>Una vez guardada la información pase a la seccion de Experiencia laboral</li>
                </ul>
            </div>

        </Fragment>
    );
}

export default PersonaFaqs;